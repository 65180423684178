import React from "react";

// Root Include
const Root = React.lazy(() => import("./pages/Home/indexRoot"));


const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));
const PageAboutusTwo = React.lazy(() => import("./pages/Pages/PageAboutusTwo"));

//Services
const WebDesign = React.lazy(() => import("./pages/WebDesign/index"));
const WebDevelopment = React.lazy(() => import("./pages/WebDevelopment/index"));

const PageContactThree = React.lazy(() => import("./pages/Pages/Contact/PageContactThree"));
// forums
const PageWorkDetail = React.lazy(() =>
  import("./pages/Pages/Work/PageWorkDetail")
);
//Email

//Help Center

//Shop

const routes = [
  //routes without Layout

  //Contct without layout
  { path: "/about", component: PageAboutusTwo },
  
  //Services
  { path: "/web-design", component: WebDesign },
  { path: "/web-development", component: WebDevelopment },


  //Help Center
  
  // Shop
  
  //Page Work
  { path: "/portfolio/:id", component: PageWorkDetail },
  //Page Profile
  
  //Page Job
  
  //Page Blog
  
  //Page Case Study
  
  // //Page Contact
  { path: "/contact", component: PageContactThree },

  // forums
  
  // //Docs
  
  // //Index Main
  
  //Index root
  { path: "/", component: Root, isWithoutLayout: true, exact: true },
  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
